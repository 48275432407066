import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme: { colors } }): string => colors.grey};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const Container = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-around;
  padding: 50px 0;
  flex-wrap: wrap;

  p {
    font-size: 12px;
    text-align: justify;
    font-family: ${({ theme: { fonts } }): string => fonts.main };
  }
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }
`
