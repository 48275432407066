import React, { FC } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import { Container, Wrapper } from './style';

const Cgu: FC = () => (
  <Wrapper>
    <Container>
      <FormattedHTMLMessage id="cgu" />
    </Container>
  </Wrapper>
);

export default Cgu;
