import React, { FC } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Terms from "../components/terms";

const IndexPage: FC = () => (
  <Layout header menuState="home">
    <SEO title="CGU" />
    <Terms />
  </Layout>
);

export default IndexPage
